<template>
    <Suspense>
      <template #default>
        <Unsubscribes />
      </template>
      <template #fallback>
        <Spinner />
      </template>
    </Suspense>
</template>

<script>
import Spinner from "@/components/Spinners/Spinner.vue";
import { defineAsyncComponent, Suspense } from "vue";
const Unsubscribes = defineAsyncComponent(() =>
  import('@/components/Users/Unsubscribes.vue')
)
export default {
  name: "Unsubscribe",
  components: {
    Unsubscribes,
    Suspense,
    Spinner
  },
};
</script>